import { FC, useCallback, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { BackButton } from 'components/UI/BackButton';
import { Button } from 'components/UI/Button';
import { Heading } from 'components/UI/Heading';
import { InputField } from 'components/UI/InputField';
import { Loader } from 'components/UI/Loader';
import { SuccessState } from 'components/UI/SuccessState';
import { useTranslations } from 'hooks/useTranslations';
import { useResendActivationMutation } from 'store/diamApi';
import { mailRegex } from 'utils/constants';
import { Description, InputFieldContainer } from './styled';

export const ResendActivationPage: FC = () => {
  const t = useTranslations();
  const [emailAddress, setEmailAddress] = useState<string | null>(null);
  const [resendActivation, resendActivationResult] =
    useResendActivationMutation();

  // useForm
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    ({ username }) => {
      resendActivation(username);
      setEmailAddress(username);
    },
    [resendActivation]
  );

  if (resendActivationResult.isLoading) {
    return <Loader />;
  }

  if (resendActivationResult.isSuccess) {
    return (
      <SuccessState
        heading={t('resend_activation.title_success')}
        details={
          <>
            <Description $center>
              {t('resend_activation.description_resend_activation_email')}
            </Description>
            <Description $center>
              <strong>{emailAddress}</strong>
            </Description>
          </>
        }
        iconType="email"
      />
    );
  }

  return (
    <>
      <BackButton />
      <Heading>{t('resend_activation.title')}</Heading>
      <Description>{t('resend_activation.description')}</Description>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputFieldContainer>
          <InputField
            id="username"
            label={t('resend_activation.label_email_address')}
            required
            placeholder={t('resend_activation.placeholder_email_address')}
            error={errors.username}
            register={register('username', {
              required: {
                value: true,
                message: t('validation.required')
              },
              pattern: {
                value: mailRegex,
                message: t('validation.email_address')
              }
            })}
          />
        </InputFieldContainer>
        <Button type="submit">
          {t('resend_activation.send_activation_link')}
        </Button>
      </form>
    </>
  );
};
