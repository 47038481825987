import { FC, useCallback, useMemo } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { Button } from 'components/UI/Button';
import { ErrorState } from 'components/UI/ErrorState';
import { Heading } from 'components/UI/Heading';
import { InputField } from 'components/UI/InputField';
import { SuccessState } from 'components/UI/SuccessState';
import { Loader } from 'components/UI/Loader';
import { UserGroups } from 'components/UI/UserGroups';
import { useQuery } from 'hooks/useQuery';
import { useTranslations } from 'hooks/useTranslations';
import * as routes from 'router/routes';
import {
  useSetNewPasswordMutation,
  useValidatePasswordRecoveryCodeQuery
} from 'store/diamApi';
import { defaultPasswordPolicy } from 'utils/constants';
import { getUserGroups } from 'utils/userUtils';
import {
  Description,
  InputFieldContainer,
  UserGroupsContainer
} from './styled';

export const ChangePasswordPage: FC = () => {
  const t = useTranslations();
  const [code, uid] = useQuery('code', 'uid');
  const validatePasswordRecoveryCode = useValidatePasswordRecoveryCodeQuery({
    userId: uid ?? '',
    recoveryCode: code ?? ''
  });
  const [setNewPassword, setNewPasswordResult] = useSetNewPasswordMutation();

  // useForm
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm();

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    ({ newPassword }) => {
      setNewPassword({
        userId: uid ?? '',
        recoveryCode: code ?? '',
        newPassword
      });
    },
    [code, setNewPassword, uid]
  );

  const minimumLength = useMemo(
    () =>
      validatePasswordRecoveryCode.data?.includedPasswordPolicy
        ?.minimumLength ?? defaultPasswordPolicy.minimumLength,
    [validatePasswordRecoveryCode.data]
  );

  const maximumLength = useMemo(
    () =>
      validatePasswordRecoveryCode.data?.includedPasswordPolicy
        ?.maximumLength ?? defaultPasswordPolicy.maximumLength,
    [validatePasswordRecoveryCode.data]
  );

  const errorMessage = useMemo(
    () =>
      validatePasswordRecoveryCode.error?.code === 'recovery.expired'
        ? 'change_password.link_expired'
        : 'change_password.link_invalid',
    [validatePasswordRecoveryCode.error?.code]
  );

  if (
    validatePasswordRecoveryCode.isLoading ||
    setNewPasswordResult.isLoading
  ) {
    return <Loader />;
  }

  if (
    !validatePasswordRecoveryCode.data ||
    validatePasswordRecoveryCode.isError
  ) {
    return (
      <ErrorState
        message={t(errorMessage)}
        link={{
          url: routes.RESET_PASSWORD,
          text: t('change_password.request_new_link')
        }}
      />
    );
  }

  if (setNewPasswordResult.isSuccess) {
    const { forward } = validatePasswordRecoveryCode.data.meta;

    return (
      <SuccessState
        heading={t('change_password.title_success')}
        details={t('change_password.description')}
        forward={forward}
        link={{ url: routes.LOGIN, text: t('general.go_to_login') }}
      />
    );
  }

  return (
    <>
      <Heading>{t('change_password.title')}</Heading>
      <Description>
        {t('account_security.description_change_password')}
      </Description>
      <Description>
        <strong>{validatePasswordRecoveryCode.data?.username}</strong>
      </Description>
      <Description>{t('account_security.description_services')}</Description>
      <UserGroupsContainer>
        <UserGroups groups={getUserGroups(validatePasswordRecoveryCode.data)} />
      </UserGroupsContainer>
      <Description>
        {t('account_security.description_change_password_logout')}
      </Description>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputFieldContainer>
          <InputField
            id="newPassword"
            label={t('account_security.label_new_password')}
            required
            isPasswordInput
            placeholder={t('account_security.placeholder_new_password')}
            error={errors.newPassword}
            register={register('newPassword', {
              required: {
                value: true,
                message: t('validation.required')
              },
              minLength: {
                value: minimumLength,
                message: t('validation.password_minimum_length', {
                  minimumLength
                })
              },
              maxLength: {
                value: maximumLength,
                message: t('validation.password_maximum_length', {
                  maximumLength
                })
              }
            })}
          />
          <InputField
            id="confirmPassword"
            label={t('account_security.label_confirm_password')}
            required
            isPasswordInput
            placeholder={t('account_security.placeholder_confirm_password')}
            error={errors.confirmPassword}
            register={register('confirmPassword', {
              required: {
                value: true,
                message: t('validation.required')
              },
              validate: (value) =>
                value === getValues('newPassword') ||
                t('validation.confirm_password')
            })}
          />
        </InputFieldContainer>
        <Button type="submit">{t('account_security.set_password')}</Button>
      </form>
    </>
  );
};
