import { FC, ReactNode } from 'react';

import { BackButton } from 'components/UI/BackButton';
import { Heading } from 'components/UI/Heading';
import { Icon, IconType } from 'components/UI/Icon';
import { Link } from 'components/UI/Link';
import { ForwardMeta } from 'models/diam';
import { ButtonLinkItem } from 'models/layout';
import { ButtonGrid, Container, Details, IconContainer } from './styled';

type Props = {
  heading: string;
  details: ReactNode;
  iconType?: IconType;
  forward?: ForwardMeta;
  link?: ButtonLinkItem;
  hideBackButton?: boolean;
};

export const SuccessState: FC<Props> = ({
  heading,
  details,
  iconType,
  forward,
  link,
  hideBackButton
}) => (
  <Container>
    {!hideBackButton && <BackButton />}
    {iconType && (
      <IconContainer>
        <Icon type={iconType} color="brand" size="extraLarge" />
      </IconContainer>
    )}
    <Heading>{heading}</Heading>
    <Details>{details}</Details>
    <ButtonGrid>
      {forward && <Link to={forward.uri}>{forward.text}</Link>}
      {link && (
        <Link to={link.url} variant={forward ? 'secondary' : 'primary'}>
          {link.text}
        </Link>
      )}
    </ButtonGrid>
  </Container>
);
