import { FC } from 'react';

import { useTranslations } from 'hooks/useTranslations';
import { footerLinks } from 'utils/constants';
import { Container, Link, Links, Logo, Text } from './styled';

export const Footer: FC = () => {
  const t = useTranslations();

  return (
    <Container>
      <Logo src="/husqvarna-group-logo-horizontal.svg" alt="Husqvarna Group" />
      <Text>{`${t('footer.description_1')} ${t('footer.description_2')}`}</Text>
      <Text>{t('footer.copyright')}</Text>
      <Links>
        {footerLinks.map(({ link, name }) => (
          <Link
            key={name}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t(name)}
          </Link>
        ))}
      </Links>
    </Container>
  );
};
